<template>
  <div>
    <!-- el-table and its children comes from Element UI -->
    <!-- http://element.eleme.io/#/en-US/component/table -->
    <!--highlight-current-row -->
    <!-- removed:
      <el-table :data="itemsList" stripe border
      -->
    <el-table
      ref="tableList"
      :data="itemsList"
      :border="true"
      @current-change="onSelectionChanged"
      width="100%"
      :cell-class-name="tableCellClassName"
      :highlight-current-row="highlightCurrentRow"
    >
      <el-table-column
        v-for="(col, colIdx) in getFixedMetadataColumns"
        :prop="col.id"
        :label="translate(col.text)"
        fixed
        :width="getWidth(col)"
        :key="colIdx"
      >
      </el-table-column>

      <el-table-column
        v-for="(col, colIdx) in getNotFixedMetadataColumns"
        :prop="col.id"
        :label="translate(col.text)"
        :key="colIdx"
        :width="getWidth(col)"
      >
        <template v-slot="scope">
          <div>
            <el-link
              :underline="false"
              v-if="isRowFoldable(scope.row) && isButtonFold(col)"
              :icon="getFoldButtonIcon(scope.row)"
              class="fold-button"
              @click="switchFoldState(scope.row)"
            ></el-link>

            <span v-else-if="isText(col)" class="word-wrap">{{
              getItemText(scope.row, col)
            }}</span>

            <div v-else-if="isStatusLabel(col)">
              <el-tooltip
                v-if="hasHint(scope.row, col)"
                placement="top"
                :effect="getTooltipEffect"
              >
                  <template #content>
                    <div v-html="getHint(scope.row, col)"></div>
                    <!-- todo - have to implement status for this -->
                    <div></div>
                </template>


                  <span>{{ getItemText(scope.row, col) }}</span>
                
              </el-tooltip>
              <div>
                <span v-if="!hasHint(scope.row, col)">{{
                  getItemText(scope.row, col)
                }}</span>
              </div>
            </div>

            <div v-else-if="isTextArray(col)">
              <div
                v-for="(itm, itmIdx) in getArrayItemValues(scope.row, col.id)"
                :key="itmIdx"
              >
                <span>{{ itm }}</span>
              </div>
            </div>

            <a
              v-else-if="isLink(col)"
              href="javascript:void(0);"
              v-on:click="openLink(col.formula, scope.row)"
              >{{ getItem(scope.row, col.id) }}</a
            >

            <div v-else-if="isComplex(col)">
              <div
                v-for="(itm, iidx) in getComplexItems( col, scope.row, scope.row )"
                :key="iidx"
                :class="itm.break == true ? '' : 'complex-item'"
              >
                <a
                  v-if="isLink(itm) && itm.text"
                  href="javascript:void(0);"
                  v-on:click="openLink(itm.value, scope.row)"
                  >{{ itm.text }}</a
                >
                <span v-if="isText(itm)">{{ itm.text }}</span>
                
                <el-input v-if="isInput(itm)" v-model="scope.row[itm.id]" ></el-input>
  
                <br v-if="itm.break == true"/>

              </div>
            </div>

            <el-select
              v-else-if="isSelect(col) && shouldShow(col, scope.row)"
              v-model="scope.row[col.id]"
              @change="onItemChanged(scope.row, col.id)"
              :class="getItemIfDuplicatedClass(col, scope.row[col.id])"
              filterable
            >
              <el-option
                v-for="option in col.options"
                :value="option.id"
                :label="option.text"
                :key="option.id"
              >
                {{ option.text }}
              </el-option>
            </el-select>

            <el-select
              v-else-if="isMultiSelect(col)"
              v-model="scope.row[col.id]"
              multiple
              filterable
              placeholder="Select"
              @change="onItemChanged(scope.row, col.id)"
              style="min-width: 360px"
            >
              <el-option
                v-for="option in col.options"
                :key="option.id"
                :label="option.text"
                :value="option.id"
              >
              </el-option>
            </el-select>

            <div v-else-if="isLinkArray(col)">
              <div
                v-if="shouldShowFolded(scope.row, col.id)"
                class="folded-item"
              >
                {{ getFoldedItemText(scope.row, col.id) }}
              </div>
              <div v-if="!shouldShowFolded(scope.row, col.id)">
                <div
                  v-for="itm in getItem(scope.row, col.id)"
                  :key="itm.id"
                  class="linkarray"
                >
                  <a
                    href="javascript:void(0);"
                    v-on:click="openLink(col.formula, itm)"
                    :class="'linkarray ' + getArrayItemClass(col, itm)"
                    >{{ getLinkArrayItemText(itm) }}</a
                  >

                  <span v-if="hasSuffix(col)">{{
                    evaluateMessage(col.suffix, itm)
                  }}</span>
                </div>
              </div>
            </div>

            <div v-else-if="isComplexArray(col)">
              <div
                v-if="shouldShowFolded(scope.row, col.id)"
                class="folded-item"
              >
                {{ getFoldedItemText(scope.row, col.id) }}
              </div>
              <div v-if="!shouldShowFolded(scope.row, col.id)">
                <div
                  v-for="arrItem in getItem(scope.row, col.id)"
                  :key="arrItem.id"
                  class="linkarray"
                >
                  <div
                    v-for="(itm,idx) in getComplexItems(col, arrItem, scope.row)"
                    :key="idx"
                    class="complex-item"
                  >
                    <a v-if="isLink(itm) && itm.text"
                      href="javascript:void(0);"
                      v-on:click="openLink(itm.value, arrItem)"
                      >{{ itm.text }} </a>
                    <span v-if="isText(itm)">{{ itm.text }}</span>

                    <div v-else-if="isStatusLabel(itm)" :class="'complex-array-item ' + getStatusLabelClass(itm)">
                      <el-tooltip v-if="hasHint(itm, col)" placement="top" :effect="getTooltipEffect" >
                          <template #content>
                            <div v-html="getHint(itm, col)"></div>
                            <!-- todo - have to implement status for this -->
                            <div></div>
                          </template>
                          <span>{{ getItemText(itm, {id: 'text'}) }}</span>
                      </el-tooltip>
                      <div>
                        <span v-if="!hasHint(itm, col)">{{
                          getItemText(itm, {id: 'text'})
                        }}</span>
                      </div>
                    </div>

                     <div v-if="itm.break == true"><br /></div>
                    

                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="isFilesArray(col)">
              <div
                v-if="shouldShowFolded(scope.row, col.id)"
                class="folded-item"
              >
                {{ getFoldedItemText(scope.row, col.id) }}
              </div>
              <div v-if="!shouldShowFolded(scope.row, col.id)">
                <cc-files-list
                  :files="getItem(scope.row, col.id)"
                  :propName="col.id"
                  :readonly="true"
                  :formula="col.formula"
                  :entity="scope.row"
                ></cc-files-list>
              </div>
            </div>

            <el-input
              v-else-if="isInput(col)"
              v-model="scope.row[col.id]"
              @change="onItemChanged(scope.row, col.id)"
            ></el-input>

            <el-input-number
              v-else-if="isDecimal(col)"
              v-model="scope.row[col.id]"
              controls-position="right"
              type="number"
              :step="getPrecision"
            ></el-input-number>

            <div v-else-if="isNumeric(col)" style="display: block">
              <el-input-number
                v-model="scope.row[col.id]"
                controls-position="right"
                size="small"
                style="width: 112px"
                @change="onItemChanged(scope.row, col.id)"
              />
              <span v-if="col.suffix">{{ col.suffix }}</span>
            </div>

            <span v-else-if="isCalculated(col)" class="word-wrap">{{
              evaluateFormula(col.formula, scope.row, col.id)
            }}</span>

            <span v-else-if="isCalculatedHidden(col)" style="display: none">{{
              evaluateFormula(col.formula, scope.row, col.id)
            }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        v-if="!hideActions"
        label=""
        :width="getWidth({ id: 'action-buttons' })"
      >
        <template v-slot="scope">
          <div class="column-actions-wrapper">
            <div class="column-actions">
              <el-button
                v-if="hasAllowedAction('moveUp', scope.row) && !readOnly"
                @click="onAction('moveUp', scope.row)"
                :type="listButtonType"
                :disabled="isFirst(scope.row)"
                :title="getActionTitle('moveUp', scope.row)"
                :icon="ArrowUp"
                size="small"
              />

              <el-button
                v-if="hasAllowedAction('moveDown', scope.row) && !readOnly"
                @click="onAction('moveDown', scope.row)"
                :type="listButtonType"
                :disabled="isLast(scope.row)"
                :title="getActionTitle('moveDown', scope.row)"
                :icon="ArrowDown"
                size="small"
              />

              <el-button
                v-if="hasAllowedItemAction('start', scope.row) && !readOnly && !scope.row.isBlocked"
                @click="confirmActionExecution('start', scope.row)"
                :type="listButtonType"
                :title="getActionTitle('start', scope.row)"
                :icon="CaretRight"
                size="small"
              />

              <el-button
                v-if="hasAllowedItemAction('pause', scope.row) && !readOnly && !scope.row.isBlocked"
                @click="confirmActionExecution('pause', scope.row)"
                :type="listButtonType"
                :title="getActionTitle('pause', scope.row)"
                :icon="VideoPause"
                size="small"
              />

              <el-button
              v-if="hasAllowedItemAction('complete', scope.row) && !readOnly && !scope.row.isBlocked"
                @click="confirmActionExecution('complete', scope.row)"
                :type="listButtonType"
                :title="getActionTitle('complete', scope.row)"
                :icon="Check"
                size="small"
              />

              <el-button
                v-if="hasAllowedItemAction('restore', scope.row) && !readOnly"
                @click="confirmActionExecution('restore', scope.row)"
                :type="listButtonType"
                :title="getActionTitle('restore', scope.row)"
                :icon="Refresh"
                size="small"
              />

              <el-button
                v-if="hasAllowedAction('edit')"
                @click="edit(scope.row)"
                :type="listButtonType"
                :icon="Edit"
                size="small"
                :title="getActionTitle('edit', scope.row)"
              />

              <cc-actions
                :allowedActions="getAllowedActions"
                :entity="scope.row"
                @onAction="onAction"
                :isListActionButtons="true"
              />

              <el-button
                v-if="hasAllowedAction('print')"
                :icon="Printer"
                size="small"
                style="margin-left: 10px"
                :title="translate('Print')"
                @click="$emit('print', scope.row)"
              />

              <el-button
                v-if="hasAllowedAction('complete-external-cooperation')"
                :icon="Check"
                size="small"
                type="primary"
                :title="translate('Complete external processing')"
                @click="
                  confirmActionExecution(
                    'complete-external-cooperation',
                    scope.row
                  )
                "
              />

              <el-button
                v-if="hasAllowedAction('delete') && !readOnly"
                @click="confirmActionExecution('delete', scope.row)"
                type="danger"
                :title="getActionTitle('delete', scope.row)"
                :icon="Delete"
                size="small"
                style="margin-left: 10px"
              />
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <cc-totals
      v-if="hasTotals"
      :metadata="getTotalsMetadata"
      :entities="items"
      notExpandable="true"
    />

    <!-- for totals not to overlap everything -->
    <div style="height: 72px" />

    <div v-if="hasAllowedAction('new')" :class="getFooterClass()">
      <el-button v-if="!readOnly" @click="create" type="primary">New</el-button>
    </div>

    <div v-if="hasAllowedAction('create')" :class="getFooterClass()">
      <el-button v-if="!readOnly" @click="onAction('create')" type="primary">{{
        translate('Create')
      }}</el-button>
    </div>

    <div
      v-if="hasRelatedItems"
      :class="getFooterClass() + ' related-items-wrapper'"
    >
      <el-link
        :icon="View"
        type="primary"
        @click="showRelatedItems"
        class="related-items-link"
        >{{ getRelatedItemsText }}</el-link
      >
    </div>
  </div>
</template>


<script>

import Vue, { markRaw } from 'vue'
import { mapActions, mapGetters } from 'vuex'
//import actions from '../../store/actions'

import {
  ArrowUp,
  ArrowDown,
  CaretRight,
  VideoPause,
  Check,
  Refresh,
  Edit,
  Printer,
  Delete,
  View,
} from '@element-plus/icons-vue'

export default {
  data() {
    return {
      selectedItemIdHasBeenApplied: false,
      ArrowUp: markRaw(ArrowUp),
      ArrowDown: markRaw(ArrowDown),
      CaretRight: markRaw(CaretRight),
      VideoPause: markRaw(VideoPause),
      Check: markRaw(Check),
      Refresh: markRaw(Refresh),
      Edit: markRaw(Edit),
      Printer: markRaw(Printer),
      Delete: markRaw(Delete),
      View: markRaw(View),
    }
  },
  name: 'CcVarList',
  props: {
    items: null, // componentList
    metadata: null, // {itemType: 'Component', allowedActions: [{action: 'edit'}, {action: 'delete'}], columns: [{id: 'id', text: 'ID'}, {id: 'name', text: 'Детайл'}, {id: 'files', text: 'Документи', type: 'filesArray'}]}
    baseroute: '', // components
    sortKey: '',
    createObjectAdditionalQueryParams: null,
    highlightCurrentRow: false,
    selectedItemId: null,
    nonFixedFooter: false,
    startActionOptions: {},
    relatedItems: { text: null, count: 0 },
    hideActions: false,
    readOnly: false,
    maxButtonsCount: '',
    rootEntity: {},
  },
  computed: {
    itemsList() {
      return this.items
    },

    ...mapGetters(['globalMetadata']),

    getFixedMetadataColumns() {
      var result = []
      var columns = this.getAllMetadataColumns
      for (var i = 0; i < columns.length; i++) {
        if (this.isFixed(columns[i])) {
          result.push(columns[i])
        }
      }

      return result
    },

    getNotFixedMetadataColumns() {
      var result = []
      var columns = this.getAllMetadataColumns
      for (var i = 0; i < columns.length; i++) {
        if (!this.isFixed(columns[i])) {
          result.push(columns[i])
        }
      }

      return result
    },

    getAllMetadataColumns() {
      if (!this.metadata || !this.metadata.columns) {
        return []
      }

      if (this.metadata.altViews && this.metadata.altViews.length > 0) {
        for (var i = 0; i < this.metadata.altViews.length; i++) {
          var altView = this.metadata.altViews[i]
          if (this.shouldUseAltColumns(altView.formulaIf) && altView.columns) {
            return altView.columns
          }
        }
      }

      return this.metadata.columns
    },

    isFoldableTable() {
      return (
        this.metadata &&
        this.metadata.columns &&
        this.isButtonFold(this.metadata.columns[0])
      )
    },

    listButtonType() {
      var appSkin = this.globalMetadata ? this.globalMetadata.appSkin : null
      if (appSkin && appSkin.styles['--list-button-type']) {
        return appSkin.styles['--list-button-type']
      }
      return 'default'
    },

    getPrecision() {
      if (
        this.globalMetadata &&
        this.globalMetadata.precision &&
        this.globalMetadata.precision > 0.000001
      ) {
        return this.globalMetadata.precision
      }

      return 0.001
    },

    hasTotals() {
      return !!this.metadata.totals
    },

    getTotalsMetadata() {
      return this.metadata ? this.metadata.totals : null
    },

    duplicatedItemsByCol() {
      var result = {}

      this.getNotFixedMetadataColumns.forEach((col) => {
        if (col.markDuplicated) {
          var singles = []
          var duplicated = []
          this.items.forEach((item) => {
            var arr = item[col.id]
            if (Array.isArray(arr)) {
              arr.forEach((arrItem) => {
                var curr = this.getLinkArrayItemText(arrItem)
                if (singles.find((n) => n === curr)) {
                  duplicated.push(curr)
                } else {
                  singles.push(curr)
                }
              })
            } else {
              var curr = arr
              if (singles.find((n) => n === curr)) {
                duplicated.push(curr)
              } else {
                singles.push(curr)
              }
            }
          })

          result[col.id] = duplicated
        }
      })

      return result
    },

    getAllowedActions() {
      if (!this.metadata.allowedActions) {
        return []
      }

      // return this.metadata.allowedActions

      // TEMP TODO: return this.metadata.allowedActions
      var temp = []
      this.metadata.allowedActions.forEach((n) => {
        if (
          // Inquiry actions
          n.action == 'createOrder' ||
          // Material order actions
          n.action == 'requestMaterial' ||
          n.action == 'receiveMaterial' ||
          // order actions
          n.action == 'ordered'
        ) {
          temp.push(n)
        }
      })

      return temp
    },

    hasRelatedItems() {
      return (
        this.relatedItems &&
        this.relatedItems.text &&
        this.relatedItems.text.length > 0 &&
        this.relatedItems.count > 0
      )
    },

    getRelatedItemsText() {
      return this.hasRelatedItems ? this.relatedItems.text : null
    },

    getTooltipEffect() {
      return document.body.style.getPropertyValue('--tooltip-effect') == 'dark'
        ? 'dark'
        : 'light'
    },
  },
  updated() {
    if (
      this.highlightCurrentRow &&
      this.selectedItemId &&
      this.selectedItemId > 0
    ) {
      if (!this.selectedItemIdHasBeenApplied && this.items) {
        var row = this.items.find((n) => n.id == this.selectedItemId)
        if (row) {
          this.$refs.tableList.setCurrentRow(row)
          this.selectedItemIdHasBeenApplied = true
        }
      }
    }
  },
  methods: {
    edit(entity) {
      const { id } = entity
      this.$router.push({ name: `${this.baseroute}.edit`, params: { id } })
    },

    view(entity) {
      const { id } = entity
      this.$router.push({ name: `${this.baseroute}.view`, params: { id } })
    },

    create() {
      if (!!this.createObjectAdditionalQueryParams) {
        // note: without: JSON.parse(JSON.stringify... doesn't work
        this.$router.push({
          name: `${this.baseroute}.new`,
          query: JSON.parse(
            JSON.stringify(this.createObjectAdditionalQueryParams)
          ),
        })
      } else {
        this.$router.push({ name: `${this.baseroute}.new` })
      }
    },

    hide() {
      this.$router.push({ name: `${this.baseroute}.index` })
    },

    evaluateFormula(formula, item, propName) {
      var rootEntity = item
      var that = this
      try {
        var result = eval(formula)
        item[propName] = result
        return result
      } catch (e) {
        console.error('ERROR: evaluateFormula: ' + formula)
        console.error(e.message)
      }

      return ''
    },

    evaluateMessage(messageFormula, item) {
      var result = messageFormula

      if (result.startsWith('`') || result.startsWith("'")) {
        try {
          result = eval(result)
        } catch (e) {
          console.error('ERROR: evaluateFormula: ' + messageFormula)
          console.error(e.message)
        }
      }

      return result
    },

    openLink(formula, item) {
      if (!formula && item && item.value && item.value.length > 1) {
        this.navigateToLink(item.value)
      } else {
        var path = eval(formula)
        if (path.startsWith('method:')) {
          var method = path.substring('method:'.length)
          this.$emit(
            'linkMethodCallback',
            this.getMethodName(method),
            this.getMethodArgs(method, item)
          )
        } else {
          this.navigateToLink(path)
        }
      }
    },

    isFixed(column) {
      return false
      // return column.id == 'id' || (column.id == 'name' && column.type != 'input')
    },

    getWidth(column) {
      if (this.isButtonFold(column)) {
        return '36'
      } else if (column.id == 'action-buttons') {
        var maxButtonsCount =
          1 * (this.maxButtonsCount ? this.maxButtonsCount : 3)
        return `${maxButtonsCount * 60}`
        // return "180"
      } else if (column.id == 'id') {
        return '80'
      } else if (column.width > 0) {
        return column.width
      }

      return ''
    },

    isButtonFold(column) {
      return column.type == 'buttonFold'
    },

    isText(column) {
      return (
        !column.type ||
        column.type == '' ||
        column.type == 'text' ||
        column.type == 'label' ||
        column.type == 'dateLabel' ||
        column.type == 'durationInUserUnits' ||
        (this.readOnly && column.type == 'numeric') ||
        (this.readOnly && column.type == 'select') ||
        (this.readOnly && column.type == 'multiSelect') ||
        (this.readOnly && column.type == 'input') ||
        (this.readOnly && column.type == 'decimal')
      )
    },

    isStatusLabel(column) {
      return column.type == 'statusLabel' || column.type == 'statusDateLabel'
    },

    isFilesArray(column) {
      return column.type == 'filesArray' || column.type == 'readonlyFilesArray'
    },

    isTextArray(column) {
      return (
        column.type == 'inputArray' ||
        column.type == 'inputLinksArray' ||
        column.type == 'emailArray'
      )
    },

    isNumeric(column) {
      return column.type == 'numeric' && !this.readOnly
    },

    isComplex(column) {
      return column.type == 'complex'
    },

    isLink(column) {
      return column.type == 'link'
    },

    isLinkArray(column) {
      return column.type == 'linkArray'
    },

    isComplexArray(column) {
      return column.type == 'complexArray' || column.type == 'statusComplexArray'
    },

    isSelect(column) {
      return column.type == 'select' && !this.readOnly
    },

    isMultiSelect(column) {
      return column.type == 'multiSelect' && !this.readOnly
    },

    isInput(column) {
      return column.type == 'input' && !this.readOnly
    },

    isCalculated(column) {
      return column.type == 'calculated'
    },

    isCalculatedHidden(column) {
      return column.type == 'calculatedHidden'
    },

    isDecimal(column) {
      return column.type == 'decimal' && !this.readOnly
    },

    getArrayItemValues(row, colId) {
      var result = []

      var arr = this.getItem(row, colId)
      if (!arr || !arr.length) {
        return result
      }

      for (var i = 0; i < arr.length; i++) {
        if (arr[i] && !!arr[i].value) {
          result.push(arr[i].value)
        }
      }

      return result
    },

    getComplexItems(col, item/*don't remove - used for js eval*/, rootEntity/*don't remove - used for js eval*/) {
      if (!this.isComplex(col) && !this.isComplexArray(col)) {
        return []
      }
      var res = eval(col.formula)
      if (res) {
        for (var i = 0; i < res.length; i++) {
          res[i].id = i
          if (res[i].value) {
            res[i].value = '"' + res[i].value + '"'
          }
        }
      }

      return res
    },

    hasSuffix(col) {
      return col && col.suffix
    },

    shouldShow(col, item) {
      if (item && col.showIfFormula && col.showIfFormula.length > 0) {
        try {
          var result = eval(col.showIfFormula)
          return result
        } catch (e) {
          console.error('ERROR: evaluateFormula: ' + e.message)
        }
      }

      return true
    },

    onSelectionChanged(item) {
      this.$emit('selectionChanged', item)
    },

    showRelatedItems() {
      this.$emit('showRelatedItems')
    },

    getAllowedAction(action) {
      if (!this.metadata.allowedActions) {
        return null
      }

      var act = this.metadata.allowedActions.find(
        (n) => n && n.action == action
      )
      return act
    },

    hasAllowedAction(action) {
      return this.getAllowedAction(action) != null
    },

    getNextStatus(action, item) {
      var act = this.getAllowedAction(action)
      if (act && act.prev && act.prev.length > 0) {

        /*
        if(item.orderItem && item.orderItem.component && item.orderItem.component.externalRefId == '8175427_100') {
          console.log('item',item)
          console.log('act.prev',act.prev)
          console.log('item.status',item.status)
        }*/
          

        var idx = act.prev.indexOf(item.status)
        if (idx >= 0) {
          return act.next
        }
      }

      return null
    },

    hasAllowedItemAction(action, entity) {
      var next = this.getNextStatus(action, entity)
      return next != null
    },

    getActionTitle(action, entity) {
      const act = this.getAllowedAction(action)
      var result =
        act && act.buttonTitle
          ? this.evaluateMessage(act.buttonTitle, entity)
          : action

      return result
    },

    confirmActionExecution(action, entity) {
      const item = entity
      var act = this.getAllowedAction(action)
      if (!act && action == 'start') {
        act = { title: 'Start work?', confirmationMessage: 'Choose worker' }
      }

      if (!act) {
        return
      }

      if (act.linkFormula || action == 'start') {
        if (act.objectAction) {
          if (!this.executeObjectAction(act.objectAction, item)) {
            return
          }
        }

        var title = this.evaluateMessage(act.title, item)
        if (title.length > 32)
          title = '<h3>' + title + '</h3>'
        // const that = this
        this.$swal({
          title: title,
          html: this.evaluateMessage(act.confirmationMessage, item),
          icon: 'question',
          input: 'select',
          inputOptions: this.startActionOptions || {},
          inputValue: item.workerId,
          showCancelButton: true,
          confirmButtonText: this.evaluateMessage(
            act.buttonTitle || 'OK',
            item
          ),

        }).then((result) => {
          if (result && result.isConfirmed && result.value) {
            this.$emit(action, item, result.value)
          }
        })
      } else {
        this.$swal({
          title: this.evaluateMessage(act.title, item),
          html: this.evaluateMessage(act.confirmationMessage, item),
          icon: 'warning',
          showCancelButton: true,
        }).then((result) => {
          if (result && result.isConfirmed) {
            this.$emit(action, item)
          }
        })
      }
    },

    async executeObjectAction(objectAction, item) {
      var a = eval(objectAction)
      if (a.startsWith('method:')) {
        var methodCall = a.substring('method:'.length)
        var methodName = this.getMethodName(methodCall)
        var paramsArr = this.getMethodArgs(methodCall, item)
        if (methodName == 'openFileDocument' && paramsArr.length > 0) {
          this.openFileDocument(paramsArr[0])
          return true
        } else if (methodName == 'void') {
          return true
        } else {
          console.error('Invalid method call: ' + objectAction)
        }
      }

      return false
    },

    getMethodName(methodCall) {
      var paramsStart = methodCall.indexOf('(')
      return paramsStart <= 0 ? null : methodCall.substring(0, paramsStart)
    },

    getMethodArgs(methodCall, item) {
      var result = []

      var paramsStart = methodCall.indexOf('(')
      var paramsEnd = methodCall.indexOf(')')
      if (paramsStart <= 0 || paramsEnd <= 0 || paramsStart > paramsEnd) {
        console.error('Invalid method call: ' + methodCall)
        return result
      }

      var params = methodCall.substring(paramsStart + 1, paramsEnd)
      params.split(',').forEach((p) => result.push(eval(p)))
      return result
    },

    getItem(row, colId) {
      var hierObj = this.findHierObjectAndPropertyName(row, colId)
      return hierObj.entity
        ? hierObj.entity[hierObj.propName]
        : null
    },

    shouldShowFolded(row, colId) {
      if (!this.isFoldableTable) {
        return false
      }

      var item = this.getItem(row, colId)
      return item && item.length > 2 && this.isRowFolded(row)
    },

    isRowFolded(row) {
      return !row.__unfolded
    },

    switchFoldState(row) {
      if (this.isRowFoldable(row)) {
        // Vue.set(row, '__unfolded', !row.__unfolded)
        row['__unfolded'] = !row.__unfolded // todo - test
      }
    },

    isRowFoldable(row) {
      if (!this.isFoldableTable) {
        return false
      }

      if (!row.__isFoldableCalculated) {
        row.__isFoldableCalculated = true
        row.__isFoldable = false

        if (this.metadata && this.metadata.columns) {
          this.metadata.columns.forEach((col) => {
            if (
              this.isFilesArray(col) ||
              this.isComplexArray(col) ||
              this.isLinkArray(col)
            ) {
              var item = this.getItem(row, col.id)
              if (item && item.length > 2) {
                row.__isFoldable = true
              }
            }
          })
        }
      }

      return row.__isFoldable
    },

    getFoldedItemText(row, colId) {
      var item = this.getItem(row, colId)
      return item && item.length ? `... x${item.length}` : ''
    },

    getFoldButtonIcon(row) {
      return this.isRowFolded(row) ? 'Plus' : 'Minus'
    },

    getItemText(row, col, temp) {
      var res = this.getItem(row, col.id)
      if (col.suffix) {
        res += ' ' + col.suffix
      }

      if (col.type == 'dateLabel' || col.type == 'statusDateLabel') {
        res = this.getFormattedDate(res)
      }
      else if (col.type == 'pricePerDurationUnits') { 
        res = this.toUserPricePerDurationUnits(res)
      }
      else if (col.type == 'durationInUserUnits') {
        res = this.toDurationInUserUnits(res)
      }
      

      return res
    },

    /* Moved to main
    getFormattedDate(src) {
      if (this.globalMetadata && this.globalMetadata.dateFormat && src) {
        var d = new Date(src)
        src = this.date2str(d, this.globalMetadata.dateFormat)
      }

      return src
    },

    date2str(x, y) {
      var z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
      }
      y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
        return ((v.length > 1 ? '0' : '') + eval('z.' + v.slice(-1))).slice(-2)
      })

      return y.replace(/(y+)/g, function (v) {
        return x.getFullYear().toString().slice(-v.length)
      })
    },*/

    getLinkArrayItemText(itm) {
      // todo - for now it is implemented only for material order item linked components
      // todo - display text if different from 'id' must be specified in metadata
      if (itm.externalRefId && itm.externalRefId.length > 0) {
        return `${itm.externalRefId} (${itm.id})`
      } else if (
        itm.component &&
        itm.component.externalRefId &&
        itm.component.externalRefId.length > 0
      ) {
        return itm.component.externalRefId
      } else if (itm.orderId) {
        return `${itm.orderId} (${itm.id})`
      } else if (!!itm.name && itm.name.length > 0) {
        return itm.name
      } else if (itm.value && itm.value.length > 0) {
        return itm.value
      } else {
        return itm.id
      }
    },

    getArrayItemClass(col, itm) {
      var duplicatedArray = this.duplicatedItemsByCol[col.id]
      if (
        duplicatedArray &&
        duplicatedArray.find((n) => n === this.getLinkArrayItemText(itm))
      ) {
        return 'duplicated-item'
      }

      return ''
    },

    getItemIfDuplicatedClass(col, itm) {
      var duplicatedArray = this.duplicatedItemsByCol[col.id]

      if (duplicatedArray && duplicatedArray.find((n) => n === itm)) {
        return 'duplicated-item'
      }

      return ''
    },

    getStatusLabelClass(row, colId) {
      if (row.alerts && row.alerts.length > 0) {
        return `status-label in-progress-warning item-alerts`
      }

      var hierObj = colId 
        ? this.findHierObjectAndPropertyName(row, colId)
        : {entity: row, propName: ''};

      if (hierObj.entity && hierObj.entity.status) {
        var st =
          this.globalMetadata.statuses[hierObj.entity.status] ||
          this.globalMetadata.statuses[hierObj.entity[hierObj.propName]]
        if (st) {
          return `status-label ${st.class}`
        }
      }

      return ''
    },

    tableCellClassName(data) {
      var row = data.row
      var columnIndex = data.columnIndex

      if (
        !this.metadata ||
        !this.metadata.columns ||
        !this.metadata.columns[columnIndex]
      ) {
        return ''
      }

      var column = this.metadata.columns[columnIndex]
      if (this.isStatusLabel(column)) {
        return this.getStatusLabelClass(row, column.id)
      } else if(column.type == 'statusComplexArray') {
        return this.getStatusLabelClass(row, column.id.replace(/\.workers$/, '.actionTypeId'))
      }

      return ''
    },

    getFooterClass() {
      return this.nonFixedFooter ? 'button-create-new' : 'footer fixed'
    },

    onItemChanged(entity, propName) {
      this.$emit('onItemValueChanged', entity, propName)
    },

    onAction(action, entity) {
      if (action == 'create') {
        this.$emit('create', entity)
      } else {
        this.$emit('onAction', action, entity)
      }
    },

    isFirst(entity) {
      return this.itemsList && this.itemsList.length > 0
        ? this.itemsList[0].id == entity.id
        : false
    },

    isLast(entity) {
      return this.itemsList && this.itemsList.length > 0
        ? this.itemsList[this.itemsList.length - 1].id == entity.id
        : false
    },

    getHint(row, col) {
      if (!col || !col.hint) {
        if (this.isStatusLabel(col)) {
          return row.status
        }
      }

      const rootEntity = row
      const item = row

      try {
        var result = eval(col.hint)
        if (this.isStatusLabel(col)) {
          result = `${row.status}<br/><br/>${result ?? ''}`
        }
        return result
      } catch (e) {
        console.error(`ERROR: getHint eval(${col.hint}) -> ${e.message}`)
      }

      return null
    },

    hasHint(row, col) {
      var h = this.getHint(row, col)
      return h && h.length > 0
    },

    shouldUseAltColumns(formulaIf) {
      var result = false
      const rootEntity = this.rootEntity
      const items = this.items
      try {
        result = eval(formulaIf) === true
      } catch (e) {
        result = false
      }

      return result
    },
  },
}
</script>

<style scoped>
.column-actions-wrapper {
  text-align: right;
}

.column-actions {
  display: inline-flex;
}

.el-select-fix {
  -moz-appearance: unset;
  -webkit-appearance: unset;
  appearance: unset;
  width: 100%;
}

.button-create-new {
  margin-top: 8px;
  margin-bottom: 8px;
}

.duplicated-item {
  border: 1px dotted var(--main-menu-active-text-color);
  padding: 2px;
}

.folded-item {
}

.fold-button {
  color: var(--main-text-color);
  font-weight: bold !important;

  font-weight: bold;
  font-size: medium;
}

.status-label .cell {
  height: inherit;
  text-align: center;
  border-spacing: 8px;
}

.complex-array-item.status-label {
  margin-left: -10px;
  margin-bottom: 4px;
  padding-left: 4px;
  border: solid 1px;
}

.word-wrap {
  text-wrap: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
