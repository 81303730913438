<template>
  <div>
    <!-- TODO - this must be converted to element.eleme.io table -->
    <table
      class="el-table el-table--fit el-table--striped el-table--border el-table--enable-row-hover el-table--enable-row-transition el-table-first-col-idx inline-var-list"
    >
      <thead>
        <tr>
          <th
            v-for="key in getMetadataColumns()"
            :key="key.id"
            :style="key.type == 'calculatedHidden' ? 'display: none;' : ''" >
            <a v-if="hasHeaderLink(key)" href="javascript:void(0);" v-on:click="openHeaderLink(key)" >{{ key.text }}</a >
            <span v-if="!hasHeaderLink(key)">{{ key.text }}</span>
          </th>
          <th v-if="hasEditButton" />
          <th v-if="hasDeleteButton" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(entry, entryIdx) in getFilteredItems"
          :class="getRowClass(entry)"
          :key="entryIdx"
        >
          <td
            v-for="(cell, cellIdx) in getItemColumns(entry)"
            :key="cellIdx"
            :style="getCellStyle(cell)"
          >
            <cc-var-control
              :readOnly="readOnly || isMandatoryActionTypeId(entry, cell)"
              :metadata="cell.meta"
              :entity="cell.entity"
              :property="cell.propName"
              :rootEntity="rootEntity"
              :onchanged="getOnChangedFunction(cell, entry)"
              :style="getCellControlStyle(cell.meta)"
              @linkMethodCallback="emitLinkMethodCallback"
            />
          </td>

          <td v-if="hasEditButton" style="width: 48px">
            <el-button
              @click="edit(entry)"
              type="primary"
              :plain="isPlainButton"
              :icon="Edit"
              size="small"
            ></el-button>
           
          </td>

          <td v-if="hasDeleteButton && canDeleteEntry(entry)" style="width: 48px">
            <el-button
              @click="removeItem(entry)"
              type="danger"
              :plain="isPlainButton"
              :icon="Delete"
              size="small"
            ></el-button>
          </td>
        </tr>

        <tr v-if="metadata && metadata.showTotals"  :key="99997">
            <td
              v-for="(cell, cellIdx) in getTotals()"
              :key="cellIdx"
              :class="cell.class"
            >
              <b>{{cell.value}}</b>
            </td>

            <td v-if="hasEditButton" style="width: 48px">
            </td>

            <td v-if="hasDeleteButton" style="width: 48px">
            </td>
          </tr>

          <tr v-if="metadata && metadata.showFooter"  :key="99998">
              <td
                v-for="(cell, cellIdx) in getFooter()"
                :key="cellIdx"
                :class="cell.class"
              >
                <b>{{ cell.value }}</b>
              </td>

              <td v-if="hasEditButton" style="width: 48px">
            </td>

            <td v-if="hasDeleteButton" style="width: 48px">
            </td>
            </tr>


          <tr v-if="metadata && metadata.newButtonFormula == 'method:addActionToAllPositions()'"  :key="99999">
            <td :colspan="2">
            <el-button v-if="!readOnly" style="margin: 8px" @click="addActionToAllPositionsVisible=true"><el-icon><Plus/></el-icon>{{ translate('Add Action') }}</el-button>
              <el-dialog :title="translate('Add Action')"
                  v-model="addActionToAllPositionsVisible"
                >
                <cc-var-group
                  :metadata="{ columns: [metadata.newItemColumns, [] ]}"
                  :entity="addActionToAllPositionsEntity"
                  :rootEntity="order"
                  :showTitle="false"
                  :singleColumn="true"
                />
                <template v-slot:footer>
                        <span class="dialog-footer">
                          <el-button @click="addActionToAllPositionsVisible=false">Close</el-button>
                          <el-button
                            v-if="!readOnly"
                            type="primary"
                            @click="addActionToAllPositionsAndClose"
                            >OK</el-button
                          >
                        </span>
                    </template>
              </el-dialog>
            </td>
          </tr>

      </tbody>
    </table>

    <el-button
      v-if="hasAddItemButton"
      @click="addItem()"
      :type="listButtonType"
      size="default"
      style="margin-top: 8px"
      :icon="Plus"
    ></el-button>
  </div>
</template>

<script>
import { markRaw } from 'vue'

import {
  Edit,
  //ArrowUp,
  //ArrowDown,
  Delete,
  Plus,
} from '@element-plus/icons-vue'

//import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { min } from 'lodash'
import { column } from 'element-plus/es/components/table-v2/src/common'

export default {
  data() {
    return {
      Edit: markRaw(Edit),
      //ArrowUp: markRaw(ArrowUp),
      //ArrowDown: markRaw(ArrowDown),
      Delete: markRaw(Delete),
      Plus: markRaw(Plus),
      addActionToAllPositionsVisible: false,
      addActionToAllPositionsEntity: {idx: 1, durationInMinutes: 0},  // defaulted for INSTALO
    }
  },
  name: 'CcInlineVarList',
  props: {
    rootEntity: {},
    items: null,
    metadata: {},
    newItemTemplate: {},
    sortKey: '',
    readOnly: false,
    showRowBorders: false,
    editButtonText: null
  },
  computed: {
    ...mapGetters(['globalMetadata']),

    isEditable() {
      return !!this.editButtonText
    },

    listButtonType() {
      var appSkin = this.globalMetadata ? this.globalMetadata.appSkin : null
      if (appSkin && appSkin.styles['--list-button-type']) {
        return appSkin.styles['--list-button-type']
      }
      return 'default'
    },

    isPlainButton() {
      return this.listButtonType != 'primary'
    },

    getFilteredItems() {
      var result = this.items ? this.items : []
      if (this.sortKey && this.sortKey.length > 1) {
        result.sort((a, b) => {
          return a[this.sortKey] > b[this.sortKey]
        })
      }

      return result;
    },

    hasEditButton() {
      return !this.readOnly && this.isEditable
    },

    hasDeleteButton() {
      return this.metadata 
        ? !this.readOnly && !this.metadata.disableItemDeletion
        : !this.readOnly
    },

    hasAddItemButton() {
      return !this.readOnly && this.metadata
         && (!this.metadata.newButtonFormula || this.metadata.newButtonFormula.length == 0)
         && !this.metadata.disableItemAdd
    },
  },
  methods: {

    mounted() {},

    getMetadataColumns() {
      return this.getActualColumns(null)
    },

    getItemColumns(item) {
      var columns = this.getActualColumns()
      var result = []

      columns.forEach((colMeta) => {
        var hierObj = this.findHierObjectAndPropertyName(item, colMeta.id)
        result.push({
          meta: colMeta,
          entity: hierObj.entity,
          propName: hierObj.propName,
        })
      })

      return result
    },

    emitLinkMethodCallback(method,args) {
      this.$emit('linkMethodCallback', method, args)
    },

    getTotals() {
      var columns = this.getActualColumns()
      var result = []
      const items = this.getFilteredItems

      columns.forEach((colMeta) => {
        if (colMeta.type == 'select') {
          result.push({
            value: '',
            class: '',
          })
        } else { 
          var total = 0;
          items.forEach(itm => {
            var hierObj = this.findHierObjectAndPropertyName(itm, colMeta.id)
            total += 1.0 * hierObj.entity[hierObj.propName]
          })

          if (colMeta.type == 'pricePerDurationUnits') {
            if (!isNaN(total))
              total = this.toUserPricePerDurationUnits(total)
          }
          else if (colMeta.type == 'durationInUserUnits') {
            if (!isNaN(total))
              total = this.toDurationInUserUnits(total)
          }

          result.push({
            value: isNaN(total) ? '' : total,
            class: 'totals' + (total < 0 ? ' negative' : ''),
          })
        }
      })

      return result
    },

    getFooter() { 
      var columns = this.getActualColumns()
      var result = []

      const rootEntity = this.rootEntity
      
      columns.forEach(c => {
        if (c.footerFormula) {
          result.push({ value: eval(c.footerFormula) })
        } else { 
          result.push({value:''})
        }
       })
      return result
    },


    getActualColumns(item) {
      if (!this.metadata) {
        return []
      }

      var columns = this.metadata.columns
      if (this.metadata.altViews && this.metadata.altViews.length > 0) {
        for (var i = 0; i < this.metadata.altViews.length; i++) {
          var altView = this.metadata.altViews[i]
          if (
            this.shouldUseAltColumns(item, altView.formulaIf) &&
            altView.columns
          ) {
            columns = altView.columns
            break
          }
        }
      }

      return columns
    },

    shouldUseAltColumns(item, formulaIf) {
      var result = false
      const rootEntity = this.rootEntity
      try {
        //console.log(formulaIf)
        result = eval(formulaIf) === true
      } catch (e) {
        result = false
      }

      return result
    },

    hasHeaderLink(col) {
      return col.headerLinkFormula && col.headerLinkFormula.length > 0
    },

    openHeaderLink(col) {
      // const isFunc = col.headerLinkFormula.startsWith('(function')
      const rootEntity = this.rootEntity
      const items = this.items
      var result = null
      try {
        result = eval(col.headerLinkFormula)
      } catch (e) {
        console.error(e)
        result = null
      }

      if (result && result.length > 0)
        this.navigateToLink(result)
    },

    addItem() {
      // this.newItemTemplate['actionTypeId'] =0
    

      var template = this.newItemTemplate
        ? this.newItemTemplate
        : this.metadata ? this.metadata.newItemTemplate : undefined
      var newItem = JSON.parse(JSON.stringify(template))

      //console.log(template)
      //console.log(newItem)

      const items = this.getFilteredItems

      if (newItem.idx) { 
        newItem.idx = this.lastIdx() * 1 + 1
      }

      items.push(newItem)

      this.shiftUpTailMandatoryActions(newItem.idx)
    },

    removeItem(item) {
      var items = this.getFilteredItems
      if (items.indexOf(item) >= 0) {
        var realIdx = this.items.indexOf(item)
        this.items.splice(realIdx, 1)
      }

      if(this.metadata.deleteButtonFormula && this.metadata.deleteButtonFormula.length > 0) {
        const rootEntity = this.rootEntity
        try {
          eval(this.metadata.deleteButtonFormula)
        } catch (e) {
          //result = false
          console.error(e)
        }
      }
    },

    minIdx() { 
      const items = this.getFilteredItems
      var result = 0
      for (var i = 0; i < items.length; i++) {
        if (items[i].isMandatory
          && items[i].mandatoryIndex < 0
          && items[i].idx > result) { 
            result = items[i].idx
          }
      }

      return result + 1
    },

    lastIdx() { 
      const items = this.getFilteredItems
      var result = this.minIdx() - 1
      for (var i = 0; i < items.length; i++) {
        if (!items[i].isMandatory && items[i].idx > result) {
          result = items[i].idx
        }
      }
      return result
    },

    getSortedTailMandatoryActions() { 
      const items = this.getFilteredItems
      var result = []
      for (var i = 0; i < items.length; i++) { 
        if (items[i].mandatoryIndex > 0) { 
          result.push(items[i])
        }
      }

      result.sort((a, b) => (a.mandatoryIndex > b.mandatoryIndex) ? 1 : -1)
      return result
    },

    getOnChangedFunction(cell, entry) { 
      if (cell.propName == 'idx' && !entry.isMandatory)
        return (entry, propName, val) => {
          this.onIdxChanged(entry, propName, val)
          if (cell.meta.onChangeFormula)
            this.evalOnChangeFormula(entry, cell.meta.onChangeFormula)
        }
        
      if (cell.meta.onChangeFormula && cell.meta.onChangeFormula.length > 0)
        return (entry, propName, val) => {
          // console.log(entry[propName])
          return this.evalOnChangeFormula(entry, cell.meta.onChangeFormula)
        } 
        
      return undefined
    },
    
    onIdxChanged(entity, propName, val) {
      const min = this.minIdx()
      if (val != 0 && val < min)
        entity.idx = min

      this.shiftUpTailMandatoryActions(entity.idx)
    },

    evalOnChangeFormula(item, formula) {
      //var result = undefined
      const rootEntity = this.rootEntity
      try {
        //console.log(formulaIf)
        //result = eval(formulaIf) === true
        eval(formula)
      } catch (e) {
        //result = false
        console.error(e)
      }

      //return result
    },

    shiftUpTailMandatoryActions(lastIdx) { 
      var tailMandatoryActions = this.getSortedTailMandatoryActions()
      if (tailMandatoryActions.length > 0 && tailMandatoryActions[0].idx <= lastIdx) {
        var nextIdx = lastIdx
        for (var i = 0; i < tailMandatoryActions.length; i++) {
          tailMandatoryActions[i].idx = ++nextIdx
        }
      }
    },

    edit(item) {
      this.$emit('onItemEdit', item)
    },

    getRowClass(entry) {
      var result = 'el-table-row-first-col-idx'
      if (entry.isMandatory) {
        result += ' mandatory-row'
      }

      return result
    },

    getCellStyle(cell) {
      var result = this.showRowBorders
        ? 'border-bottom: 1px solid #ebeef5;'
        : ''

      if (cell && cell.meta && cell.meta.type == 'calculatedHidden')
        result += ' display: none'

      return result
    },

    isMandatoryActionTypeId(entry, cell) {
      //console.log(entry, entry.isMandatory && (cell.propName == 'actionTypeId' || cell.propName == 'idx'))
      //console.log(entry.mandatoryIndex)
      // hack to disable changing of action type id of mandatory actions
      return entry.isMandatory && (cell.propName == 'actionTypeId' || cell.propName == 'idx')

    },

    addActionToAllPositionsAndClose() { 
      this.rootEntity.items.forEach(item => {
        var existing = item.actions.find(n => n.actionTypeId == this.addActionToAllPositionsEntity.actionTypeId)
        if (!existing) {
          item.actions.push(JSON.parse(JSON.stringify(this.addActionToAllPositionsEntity)))
        } else if (this.addActionToAllPositionsEntity['_update_index'] == true) { 
          existing.idx = this.addActionToAllPositionsEntity.idx
        }
      })

      if(this.rootEntity.summarizedItem) {
        var existing = this.rootEntity.summarizedItem.actions.find(n => n.actionTypeId == this.addActionToAllPositionsEntity.actionTypeId)
        if (!existing) {
          this.rootEntity.summarizedItem.actions.push(JSON.parse(JSON.stringify(this.addActionToAllPositionsEntity)))
        } else if (this.addActionToAllPositionsEntity['_update_index'] == true) { 
          existing.idx = this.addActionToAllPositionsEntity.idx
        }
      }

      this.addActionToAllPositionsVisible = false
    },

    getCellControlStyle(cellMeta) {
      var result = ''
      if(cellMeta.type == 'complex')
        result += 'text-wrap: auto; '

      if(cellMeta.width && cellMeta.width > 0)
        result += `width:${cellMeta.width}px`
      return result
    },

    canDeleteEntry(entry) {
      return  !entry.isMandatory
    },
  },
}
</script>

<style scoped>
.el-button--mini {
  min-width: 32px;
  margin-left: 0px;
  margin-right: 0px;
}

tr > td:last-child {
  text-align: right;
}

tr.mandatory-row {
  background-color: var(--table-master-row-background-color);
}

.totals {
  font-size: 14px;
}
.negative {
  color: red;
}
</style>
